















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Notification } from './Notification.service';

@Component({
  name: 'LempiNotification',
})
export default class LempiNotification extends Vue {
  @Prop({ required: true }) notification!: Notification;

  get color() {
    const { color } = this.notification.options;
    if (!color) {
      return '';
    }
    return `${color} darken-4`;
  }

  get textColor() {
    const { color } = this.notification.options;
    if (!color) {
      return '';
    }
    return `${color}--text text--darken-4`;
  }
}
