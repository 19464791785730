export const login = async (username: string, password: string) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const body = JSON.stringify({
    username,
    password,
  });

  const res = await fetch('/api/auth/', {
    method: 'POST',
    headers,
    body,
  });

  const json: { token: string } = await res.json();
  return json.token;
};

export const register = async (email: string, password: string) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const body = JSON.stringify({
    email,
    password,
  });

  const res = await fetch('/api/user/register/', {
    method: 'POST',
    headers,
    body,
  });

  const json: { email: string | string[] } = await res.json();
  return json;
};

export default class LoginApi {
  private headers = () => {
    const headers = new Headers();
    const csrfTokenRegex = document.cookie.match(/csrftoken=(\w+)(;|$|\s)/);
    headers.append('Content-Type', 'application/json');
    if (csrfTokenRegex) {
      headers.append('X-CSRFToken', csrfTokenRegex[1]);
    }
    return headers;
  };

  async login(username: string, password: string) {
    const headers = this.headers();

    const body = JSON.stringify({
      username,
      password,
    });

    const res = await fetch('/api/auth/', {
      method: 'POST',
      headers,
      body,
    });

    const json: { token: string } = await res.json();
    return json.token;
  }
}
