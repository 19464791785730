import Vue from 'vue';
import Vuex from 'vuex';
import UserStore from './modules/user';

Vue.use(Vuex);

const config = {
  modules: {
    user: UserStore,
  },
};

export default new Vuex.Store(config);
