import store from '@/store';
import UserStore from '@/store/modules/user';
import { getModule } from 'vuex-module-decorators';
import { Wallet, WalletDetail } from '../models/Wallet';

const getDefaultHeaders = () => {
  const headers = new Headers();
  const userStore = getModule(UserStore, store);
  headers.append('Authorization', `Token ${userStore.authToken}`);
  headers.append('Content-Type', 'application/json');
  return headers;
};

export const listWallets = async () => {
  const headers = getDefaultHeaders();
  const res = await fetch('/api/wallet/', { headers });
  const json: Wallet[] = await res.json();
  return json;
};

export const getWalletDetail = async (uuid: string) => {
  const headers = getDefaultHeaders();
  const res = await fetch(`/api/wallet/${uuid}`, { headers });
  const json: WalletDetail = await res.json();
  return json;
};

interface WalletDepositResponse {
  status: number;
  error: string;
}

interface DepositRequestPayload {
  reference: string;
  bank: number;
  amount: number;
}

export const requestWalletDeposit = async (
  uuid: string,
  data: DepositRequestPayload
): Promise<WalletDepositResponse> => {
  const headers = getDefaultHeaders();
  const amount = data.amount * 100;
  const res = await fetch(`/api/wallet/${uuid}/deposit_request/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...data,
      amount,
    }),
  });
  const json = await res.json();
  return {
    status: res.status,
    error: json.error || '',
  };
};

interface WalletWithdrawResponse {
  status: number;
  error: string;
}

interface WithdrawRequestPayload {
  account: string;
  bank: number;
  amount: number;
}

const REASON = [
  'Sin Razon',
  'Dinero insuficiente',
  'Referencia no encontrada',
  'La referencia ya ha sido procesada',
];

export const requestWalletWithdraw = async (
  uuid: string,
  data: WithdrawRequestPayload
): Promise<WalletWithdrawResponse> => {
  const headers = getDefaultHeaders();
  const res = await fetch(`/api/wallet/${uuid}/withdraw_request/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      bank: data.bank,
      reference: data.account,
      amount: data.amount,
    }),
  });
  const json = await res.json().catch((e: Error) => e);
  if (json instanceof Error) {
    return {
      status: res.status,
      error: 'Error en el Servidor',
    };
  }
  const reason = REASON[json.reason] || 'Desconocido';
  const error = json.status === 0 ? `Rechazado por: ${reason}` : '';
  return {
    status: res.status,
    error: error || json.error,
  };
};

export const sendMoney = async (myWallet: string, wallet: string, amount: number) => {
  const headers = getDefaultHeaders();
  const res = await fetch(`/api/wallet/${myWallet}/transfer/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      amount,
      wallet,
    }),
  });
  const errorInfo: Record<number, string> = {
    404: 'Billetera no encontrada',
  };
  const isSent = res.status === 200;
  return {
    isSent,
    error: {
      message: errorInfo[res.status] || 'No se pudo procesar, revise la cuenta',
    },
  };
};

export const syncWallets = async () => {
  const userStore = getModule(UserStore, store);
  const wallets = await listWallets();
  userStore.setWallets(wallets);
};
