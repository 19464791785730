import { NavigationGuard } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import UserStore from '@/store/modules/user';
import store from '@/store';

const authGuard: NavigationGuard = (to, from, next) => {
  const userStore = getModule(UserStore, store);
  if (!userStore.isLoggedIn) {
    next({
      name: 'login',
    });
    return;
  }
  next();
};

export default authGuard;
