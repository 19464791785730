import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LoginApi, { register } from '@/common/service/LempiApi';
import { Wallet } from '@/common/models/Wallet';

interface UserAuth {
  username: string;
  password: string;
}

interface UserState {
  authToken: string;
  login(auth: UserAuth): void;
  logout(): void;
  register(auth: UserAuth): void;
  isLoggedIn: boolean;
  wallets: Wallet[];
}

const loginApi = new LoginApi();

@Module({ namespaced: true, name: 'user' })
class UserStore extends VuexModule implements UserState {
  authToken = localStorage.getItem('token') || '';

  wallets: Wallet[] = [];

  @Action
  async login({ username, password }: UserAuth) {
    const token = await loginApi.login(username, password);
    if (!token) {
      return;
    }
    localStorage.setItem('token', token);
    this.context.commit('setAuthToken', token);
  }

  @Action
  async register({ username, password }: UserAuth) {
    const response = await register(username, password);
    this.login({ username, password });
    return response;
  }

  @Mutation
  setAuthToken(token: string) {
    this.authToken = token;
  }

  @Mutation
  logout() {
    localStorage.setItem('token', '');
    this.authToken = '';
  }

  @Mutation
  setWallets(wallets: Wallet[]) {
    this.wallets = wallets;
    localStorage.setItem('userData', JSON.stringify(this));
  }

  get isLoggedIn() {
    return Boolean(this.authToken);
  }

  get mainWallet() {
    if (this.wallets.length === 0) {
      return '';
    }
    return this.wallets[0].uuid;
  }

  get qrCodeUrl() {
    return `${window.location.origin}/send?account=${this.mainWallet}`;
  }

  @Action
  async generateQrCode(amount?: number | string) {
    const amountParam = amount ? `&amount=${amount}` : '';
    return `${this.qrCodeUrl}${amountParam}`;
  }
}

export default UserStore;
