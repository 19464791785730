interface NotificationOptions {
  color: string;
  text: string;
  timeout: number;
}

const defaultOptions: Partial<NotificationOptions> = {
  color: '',
  text: '',
  timeout: 2000,
};

export class Notification {
  isShown = false;

  id = 1;

  options = defaultOptions;

  hide() {
    this.isShown = false;
    // setTimeout(() => {
    //   this.options = defaultOptions;
    // }, 50);
  }

  show(options: Partial<NotificationOptions> = {}) {
    this.id += 1;
    this.isShown = true;
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }
}

export default new Notification();
