































































import { Vue, Component } from 'vue-property-decorator';
import LempiNotification from '@/components/Notification.vue';
import notification from '@/components/Notification.service';
import { getModule } from 'vuex-module-decorators';
import UserStore from './store/modules/user';
import { listWallets } from './common/service/WalletApi';

@Component({
  name: 'App',
  components: {
    LempiNotification,
  },
})
export default class App extends Vue {
  notification = notification;

  userStore = getModule(UserStore, this.$store);

  value = 0;

  async created() {
    if (!this.loggedIn) {
      return;
    }
    const wallets = await listWallets();
    this.userStore.setWallets(wallets);
  }

  // eslint-disable-next-line class-methods-use-this
  fullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  get userActionText() {
    return this.loggedIn ? 'Cerrar Sesión' : 'Iniciar Sesión';
  }

  get userAction() {
    return this.loggedIn ? 'logout' : 'login';
  }

  get loggedIn() {
    return this.userStore.isLoggedIn;
  }
}
