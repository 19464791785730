



















import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import RegisterLayout from '@/components/RegisterLayout.vue';
import UserStore from '@/store/modules/user';

@Component({
  name: 'Login',
  components: {
    RegisterLayout,
  },
})
export default class Login extends Vue {
  userStore = getModule(UserStore, this.$store);

  email = '';

  password = '';

  snackbar = false;

  async login() {
    await this.userStore.login({
      username: this.email,
      password: this.password,
    });
    if (!this.userStore.authToken) {
      this.snackbar = true;
      return;
    }
    this.$router.push({
      name: 'app-home',
    });
  }
}
