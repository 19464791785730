import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import RouterContainer from './RouterContainer.vue';
import authGuard from './authGuard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'app-home',
    component: () => import(/* webpackChunkName: "app-home" */ '../views/AppHome.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
  },
  {
    path: '/transaction/:uuid',
    name: 'transaction',
    component: () => import(/* webpackChunkName: "transaction" */ '@/views/Transaction.vue'),
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import(/* webpackChunkName: "transaction" */ '@/views/Deposit.vue'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "transaction" */ '@/views/Wallet.vue'),
  },
  {
    path: '/wallet/:id',
    component: RouterContainer,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "wallet" */ '@/views/WalletDetail.vue'),
        name: 'wallet-detail',
      },
      {
        path: 'deposit',
        component: () => import(/* webpackChunkName: "wallet" */ '@/views/Deposit.vue'),
        name: 'wallet-deposit',
      },
      {
        path: 'withdraw',
        component: () => import(/* webpackChunkName: "wallet" */ '@/views/Withdraw.vue'),
        name: 'wallet-withdraw',
      },
    ],
  },
  {
    path: '/bank',
    name: 'new-bank',
    component: () => import(/* webpackChunkName: "bank" */ '@/views/Bank.vue'),
  },
  {
    path: '/send',
    name: 'send',
    component: () => import(/* webpackChunkName: "core" */ '@/views/Send.vue'),
  },
  {
    path: '/receive',
    name: 'receive',
    component: () => import(/* webpackChunkName: "core" */ '@/views/Receive.vue'),
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import(/* webpackChunkName: "core" */ '@/views/Requests.vue'),
  },
  {
    path: '/*',
    name: 'error-not-found',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "error" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const PUBLIC_ROUTES = ['login', 'logout', 'register'];

router.beforeEach((to, from, next) => {
  if (!PUBLIC_ROUTES.includes(to.name || '')) {
    return authGuard(to, from, next);
  }
  return next();
});

export default router;
